import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faInstagram,
  faTwitter,
  faLinkedin,
  faWikipediaW,
} from "@fortawesome/free-brands-svg-icons"

const leadersData = [
  {
    name: `Dr. Fernando Flores`,
    role: `Founder`,
    bio: `As an accomplished entrepreneur, author, and former politician, Dr. Flores has spent the last five decades helping individuals and organizations from around the world improve coordination, innovation, and productivity. His research on business design also spans decades, pioneering language/action perspective, groupware, and management cybernetics.`,
    pic: `ff.png`,
  },

  {
    name: `Gloria Flores`,
    role: `President`,
    bio: `Gloria is a co-founder of Pluralistic Networks, and author of Learning to Learn and the Navigation of Moods: The Meta-Skill for the Acquisition of Skills. After obtaining her BS in business administration from UC Berkeley and her JD from Cornell Law School, Gloria worked as an attorney at Orrick, Herrington and Sutcliffe. Subsequently, Gloria joined Business Design Associates to pursue her true passion: helping people become more effective by learning to communicate and coordinate better with others. At Business Design Associates, she led large client engagements focused on the development of skills for individuals and teams that enhanced efficiency, flexibility and customer satisfaction while creating a style of responsibility, ownership and innovation.`,
    pic: `gloria.png`,
  },

  {
    name: `B. Scot Rouse`,
    role: `Director of Research
`,
    bio: `“B” holds a PhD in Philosophy from Northwestern University. His scholarly work has appeared in academic journals such as European Journal of Philosophy and Telos. He has studied in Berkeley, Berlin, Chicago, Frankfurt, and Paris. Before joining Pluralistic Networks, he was a visiting lecturer in Philosophy at UC Berkeley. Rousse is also a lifelong musician. He has drummed on dozens of records and has toured all over the world, including a recent tour with stops in Mongolia, China, Korea, and Japan.`,
    pic: `b.png`,
  },
  {
    name: `Javiera Flores`,
    role: ` Director of Learning and Development
`,
    bio: `Javiera manages Pluralistic Network’s learning and development programs and is a Senior Coach. At Pluralistic Networks, and before that at Business Design Associates, Javiera has successfully led learning and development programs for dozens of companies in the U.S, Latin America and Europe, focused on teaching people how to work more effectively together, innovate, and lead. Javiera obtained her BA in Sociology and Social Welfare from the University of California, Davis.`,
    pic: `javi.png`,
  },
  {
    name: `Massimo Scapini`,
    role: ` Vice President, Operations and Growth
`,
    bio: `Massimo is responsible for operations and growth at Pluralistic Networks. Prior to joining Pluralistic he was co-founder and CEO of School 4 One, a digital platform using standards based grading to track student progress and deliver personalized feedback, an Associate at a Wall Street investment bank, a Research Director at a think tank in Chile, and an Analyst for the Ministry of Economics in Chile.`,
    pic: `massimo.png`,
  },
  {
    name: `Pablo Flores`,
    role: `Vice President, Engineering
`,
    bio: `Pablo leads the development of Pluralistic Network’s on-demand, AI guides. Prior to joining Pluralistic, Pablo served in various VP, Director and Head of Engineering roles at Stub Hub, Responsys (acquired by Oracle), Vision Consulting, and Action Technologies. He holds a BS in Computer Science from University of California, Santa Barbara.`,
    pic: `pablo.png`,
  },
]
const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <div
      class="maxWidth pagePadding"
      style={{
        marginTop: "78px",
      }}
    >
      <div
        style={{ marginTop: "65px", marginBottom: "45px", textAlign: "center" }}
      >
        {" "}
        <span class=" pageSub2">Our Mission</span>
      </div>
      <div class="PnBody ">
        In order to rise to the increasingly complex challenges that we, as
        humans, face in today’s day and age, we must all work to cultivate a
        sense of pluralism. In other words, we must recognize that our way of
        seeing the world is but one of many.
        <br />
        <br />
        A pluralistic network, then, is one made up of individuals who reflect
        and embrace the diversity of the world. Pluralistic individuals see the
        figurative “borders” between countries and communities not as
        uncrossable boundaries, but as places where we can meet halfway, so to
        speak. By choosing to participate in pluralistic networks, we can begin
        to define and accomplish a shared mission that we otherwise would not be
        able to accomplish on our own.
        <br />
        <br />
        Whether we’re at work, at play, or at home, we are unavoidably connected
        to people from all over the world. In organizations large and small,
        project teams are becoming increasingly virtual, made up of people in
        different places, from different cultural and professional backgrounds,
        and with different value systems. To flourish (let alone survive) in our
        new world requires that we develop the necessary skills for working well
        with others. Our mission is to help people and teams learn these skills.
        <br />
        <br />
        To achieve this mission, we at Pluralistic Networks have developed (and
        continue to develop) a number of engaging, research-backed educational
        experiences that enable people to work better together to co-create the
        future they want to see.
      </div>
      <div
        style={{ marginTop: "65px", marginBottom: "45px", textAlign: "center" }}
      >
        <span class=" pageSub2">Our leadership team</span>
      </div>
      <div style={{ marginTop: "8px", marginBottom: "16px" }} class="topLine">
        {" "}
        &nbsp;
      </div>

      <div class="team-container">
        <div id="person-row">
          <div class="imgColumn">
            <img width="300px" src={`/images/people/ff.png`}></img>
          </div>
          <div class="bioColumn">
            <div class="pubTitle" style={{ fontSize: "20px" }}>
              Fernando Flores, Co-founder
            </div>
            As an accomplished entrepreneur, author, and former politician, Dr.
            Flores has spent the last five decades helping individuals and
            organizations from around the world improve coordination,
            innovation, and productivity. His research on business design also
            spans decades, pioneering{" "}
            <a
              href="https://en.wikipedia.org/wiki/Language/action_perspective"
              target="blank"
            >
              language/action perspective
            </a>
            ,{" "}
            <a
              href="https://en.wikipedia.org/wiki/Collaborative_software"
              target="blank"
            >
              groupware
            </a>
            , and{" "}
            <a
              href="https://en.wikipedia.org/wiki/Project_Cybersyn"
              target="blank"
            >
              management cybernetics
            </a>
            .
            <br />
            <div class="socials">
              <a
                class="social-icon"
                href="https://www.instagram.com/cffloresl/"
                target="blank"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a
                class="social-icon"
                href="https://twitter.com/CFFloresL"
                target="blank"
              >
                <FontAwesomeIcon icon={faTwitter} />
              </a>
              <a
                class="social-icon"
                href="https://en.wikipedia.org/wiki/Fernando_Flores"
                target="blank"
              >
                <FontAwesomeIcon icon={faWikipediaW} />
              </a>
            </div>
          </div>
        </div>

        <div id="person-row-flipped">
          <div class="bioColumn">
            <div class="pubTitle" style={{ fontSize: "20px" }}>
              Gloria Flores, President and Co-founder
            </div>
            Gloria Flores is a longtime business consultant and author of the
            book{" "}
            <a
              href="https://www.amazon.com/dp/0692801790/ref=cm_sw_r_cp_ep_dp_WIKuyb8JM07G0"
              target="blank"
            >
              "Learning to Learn and the Navigation of Moods: The Meta-Skill for
              the Acquisition of Skills”
            </a>
            . She has 20+ years of experience helping teams work better together
            to achieve their goals.
            <br />
            <div class="socials">
              <a
                class="social-icon"
                href="https://www.linkedin.com/in/gloria-flores-7969248/"
                target="blank"
              >
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
            </div>
          </div>

          <div class="imgColumn">
            <img width="300px" src={`/images/people/gloria.png`}></img>
          </div>
        </div>

        <div id="person-row">
          <div class="imgColumn">
            <img width="300px" src={`/images/people/b.png`}></img>
          </div>

          <div class="bioColumn">
            <div class="pubTitle" style={{ fontSize: "20px" }}>
              B. Scot Rousse, Director of Research
            </div>
            “B” holds a PhD in Philosophy from Northwestern University, and has
            published recent scholarly research in European Journal of
            Philosophy, The Cambridge Heidegger Lexicon, and a volume on
            Teaching and Learning for Adult Skill Acquisition. Before joining
            Pluralistic Networks, he was a lecturer in Philosophy at UC
            Berkeley. He is also a lifelong musician, having toured all over the
            world.
            <br />
            <div class="socials">
              <a
                class="social-icon"
                href="https://www.linkedin.com/in/b-scot-rousse-phd-0aaa085b/"
                target="blank"
              >
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
            </div>
          </div>
        </div>

        <div id="person-row-flipped">
          <div class="bioColumn">
            <div class="pubTitle" style={{ fontSize: "20px" }}>
              Javiera Flores, Director of Learning & Development
            </div>
            At Pluralistic Networks, Javiera oversees learning and development
            programs for companies in the US, Latin America, and Europe, focused
            on teaching people how to collaborate, innovate, and lead.
            <br />
            <div class="socials">
              <a
                class="social-icon"
                href="https://www.linkedin.com/in/javiera-flores-b319a76/"
                target="blank"
              >
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
            </div>
          </div>

          <div class="imgColumn">
            <img width="300px" src={`/images/people/javi.png`}></img>
          </div>
        </div>

        <div id="person-row">
          <div class="imgColumn">
            <img width="300px" src={`/images/people/massimo.png`}></img>
          </div>

          <div class="bioColumn">
            <div class="pubTitle" style={{ fontSize: "20px" }}>
              Massimo Scapini, Vice President, Operations & Growth
            </div>
            Massimo has a background in finance, having worked on Wall Street
            and for the Ministry of Economics in Chile. Prior to joining
            Pluralistic Networks, he was co-founder and CEO of School 4 One, a
            personalized education platform.
            <br />
            <div class="socials">
              <a
                class="social-icon"
                href="https://www.linkedin.com/in/massimo-scapini-1867524/"
                target="blank"
              >
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
            </div>
          </div>
        </div>

        <div id="person-row-flipped">
          <div class="bioColumn">
            <div class="pubTitle" style={{ fontSize: "20px" }}>
              Pablo Flores, Vice President, Engineering
            </div>
            Pablo leads all of Pluralistic Networks’ engineering projects,
            including <Link to="/navigator">Mood Navigator.</Link> Before
            Pluralistic, he held various engineering leadership roles at Stub
            Hub, Responsys (acquired by Oracle), Vision Consulting, and Action
            Technologies.
            <br />
            <div class="socials">
              <a
                class="social-icon"
                href="https://www.linkedin.com/in/pflores/"
                target="blank"
              >
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
            </div>
          </div>

          <div class="imgColumn">
            <img width="300px" src={`/images/people/pablo.png`}></img>
          </div>
        </div>

        <br />
        <br />
        <br />
      </div>
    </div>
  </Layout>
)

export default AboutPage
